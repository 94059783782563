import { TranslationsNederlands } from "./Translations/nederlands.jsx"

const cAvailableLanguages = [
  {Id:null, Code:null, Caption:"English",Translations:null},
  {Id:"nederlands", Code:19, Caption:"Nederlands",Translations:TranslationsNederlands()}
]

let mSelectedLanguage = sessionStorage.getItem('LanguageId') ?? ""

export function GetAvailableLanguages()
{
    return cAvailableLanguages
}

export function GetLanguageCaption()
{
  let result = "English";
  try 
  {
    cAvailableLanguages.forEach(language => {
      if (language.Id === mSelectedLanguage)
      {
        result = language.Caption
      }
    })
  } 
  catch (ex) { }  

  return result
}

export function GetLanguageCode()
{
  let result = null;
  try 
  {
    cAvailableLanguages.forEach(language => {
      if (language.Id === mSelectedLanguage)
      {
        result = language.Code
      }
    })
  } 
  catch (ex) { }  

  return result
}


export function GetLanguageId()
{
  return mSelectedLanguage
}

export function ResetLanguageId()
{
  mSelectedLanguage = sessionStorage.getItem('LanguageId') ?? ""
}

export async function SetLanguageId(aLanguageId)
{
  mSelectedLanguage = aLanguageId
  sessionStorage.setItem('LanguageId',aLanguageId)
}

export function Translate(aText) 
{
  let result = null;
  try 
  {
    cAvailableLanguages.forEach(language => {
      if (language.Id === mSelectedLanguage)
      {
        language.Translations.forEach(translation => {
          if (translation.id === aText)
          {
            result = translation.value
          }
        })
      }
    })
  } 
  catch (ex) 
  {
    console.log("Translate error: " + ex)
  }

  return result ? result : aText
}