import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_AddNewTenant,TenantFrontendApi_GetReseller} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import Footer from "./Footer"
import BlueBar from "./BlueBar"

import HomeLogo from "./Assets/HomeLogo.png"
import Slash from './Assets/slash.png';

import './Tenant.css';
import './EditUser.css';
import './EditTenant.css';
import './Licences.css';
import './AddNewTenant.css';

function AddNewTenant(){

    const navigate = useNavigate()
    const [mRepaint,SetRepaint] = useState()

    const [mTenantName,SetTenantName]=useState()
    const [mTenantAddress1,SetTenantAddress1]=useState()
    const [mTenantAddress2,SetTenantAddress2]=useState()
    const [mTenantPostalCode,SetTenantPostalCode]=useState()
    const [mTenantCity,SetTenantCity]=useState()
    const [mTenantCountry,SetTenantCountry]=useState()
    const [mTenantPhone,SetTenantPhone]=useState()
    const [mTenantVATNumber,SetTenantVATNumber]=useState()
    const [mTenantApiSettings,SetTenantApiSettings] = useState()
    const [mTenantCustomId,SetTenantCustomId]=useState()

    const [mReseller,SetReseller] = useState("")
    
    useEffect(() => {

        async function GetReseller()
        {
            try
            {  
                console.log("Getting reseller")
                const ResellerResponse = await TenantFrontendApi_GetReseller(sessionStorage.getItem("token"),sessionStorage.getItem("resellerguid"))
                SetReseller(ResellerResponse.data)
            }

            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetReseller()
        
    }, []);

    function handleTenantPage(){
        return(
            navigate('../reseller')
        )
    }

    function AddNewTenantButton(){
            return(
                AddNewTenantRequest()
            )
    }
    
    function HomePage(){
        return(
            navigate('../reseller')
        )
    }

    async function AddNewTenantRequest() {
        try
        {
            console.log("Creating Tenant")
            const AddNewTenantResponse = await TenantFrontendApi_AddNewTenant(sessionStorage.getItem("token"),sessionStorage.getItem("resellerguid"),mTenantName,mTenantAddress1,mTenantAddress2,mTenantPostalCode,mTenantCity,mTenantCountry,mTenantPhone,mTenantVATNumber,mTenantApiSettings,mTenantCustomId)
            navigate('../tenant?tenantguid=' + AddNewTenantResponse.data.TenantGuid)
        }

        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            alert('Tenant is not created')
        }
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    const SetDefaultTenantApiSettings = (aDefaultTenantApiSettings) =>
    {
        SetReseller(prevReseller => ({
            ...prevReseller,
            DefaultTenantApiSettings: aDefaultTenantApiSettings
        }));
        SetTenantApiSettings(aDefaultTenantApiSettings)
        SetRepaint(!mRepaint);
    }

    return(

        <span>

            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={1} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                
                    <tr className="pagetop3">
                        <td colSpan={6} height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    <tr height="60">
                                        <td width="40" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={()=>HomePage()}></img></td>
                                        <td width="40" align="left" text-align="button" className="bold underline pointer" onClick={()=>handleTenantPage()}>{Translate("Tenants")}</td>
                                        <td width="20" align="left"><img alt="Slash logo" className="slashImage" src={Slash}></img></td>
                                        <td align="left" className="bold">{Translate("Add tenant")}</td>                                        
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    
                    <tr height="20"></tr>
                </tbody>
            </table>

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                

                    <tr className="pagetop4">
                        <td colSpan={4} height="75" valign="left" className="activetenants width30">
                            {Translate("Add tenant")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>                
                </tbody>
            </table>
            
            <table className="width1200 cloudctibackgroundtable" align="center">
                <tbody>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall"> {Translate("Name")} <span className="red">*</span></td>
                    </tr>
                    
                    <tr height="10"/>
                    
                    <tr height="40">
                        <td className="widthaddtenanttable"></td>
                        <td><input className="inputtd" onChange={e=>SetTenantName(e.target.value)}></input></td>
                        <td></td>
                    </tr>
                    
                    <tr height="20"/>
                    
                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("Address")} 1 </td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className=""><input className="inputtd" onChange={e=>SetTenantAddress1(e.target.value)}></input></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("Address")} 2 </td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <input className="inputtd" onChange={e=>SetTenantAddress2(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>
                    
                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("Postal code")}</td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <input className="inputtd" onChange={e=>SetTenantPostalCode(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("City")}</td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <input className="inputtd" onChange={e=>SetTenantCity(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("Country")}</td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <input className="inputtd" onChange={e=>SetTenantCountry(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("Phone")}</td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <input className="inputtd" onChange={e=>SetTenantPhone(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>
                    
                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("VAT number")}</td>
                    </tr>

                   <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <input className="inputtd" onChange={e=>SetTenantVATNumber(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                    
                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("API settings")}</td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="110">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <textarea className="textareaaddtenant" value={mReseller.DefaultTenantApiSettings} onChange={e=>SetDefaultTenantApiSettings(e.target.value)}></textarea>
                        </td>
                        <td></td>
                    </tr>
                    
                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr>
                        <td className="widthaddtenanttable"></td>
                        <td className="bold fontsizesmall">{Translate("Custom ID")}</td>
                    </tr>

                    <tr height="10"/>

                    <tr  height="40">
                        <td className="widthaddtenanttable"></td>
                        <td width="1000" className="">
                            <input className="inputtd" onChange={e=>SetTenantCustomId(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>
                
                    <tr height="20"/>

                    <tr height="20" ></tr>

                    <tr height="30"/>
                
                    <tr>
                        <td className="widthaddtenanttable"/>
                        <td><button disabled={!mTenantName} type="button"  className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=>AddNewTenantButton()}>{Translate("Add tenant")}</button></td>
                    </tr>

                    <tr height="40"/>
            
                </tbody>
            </table>
            
            {/*Free space*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            {/*Footer Table*/}
            <Footer/>
        </span>
    )
}

export default AddNewTenant