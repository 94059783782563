import axios from "axios";

export async function TenantFrontendApi_GetTenantList(aSecurityToken)
{
    return await axios.get(
        'https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenantlist',                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }                
    )
}

export async function TenantFrontendApi_GetTenant(aSecurityToken,aTenantGuid)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenant?tenantguid=" + aTenantGuid,                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }                
    )
}

export async function TenantFrontendApi_GetUserList(aSecurityToken,aTenantGuid)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/userlist?tenantguid=" + aTenantGuid + "&returninactive=true",                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }                
    )

}

export async function TenantFrontendApi_GetLicenceList(aSecurityToken,aTenantGuid)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenantlicencelist?tenantguid=" + aTenantGuid,                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }   
    ) 
}

export async function TenantFrontendApi_GetUser(aSecurityToken,aUserGuid)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/User?userguid=" + aUserGuid,                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }   
    )
}

export async function TenantFrontendApi_UpdateUser(aSecurityToken,aUserGuid,aEmail,aName,aUserApiSetting,aEnableRecognitionConfiguration,aIsTenantAdministrator,aTenantGuid,aLicenceGuid)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "Email":aEmail,
            "Name":aName,
            "ApiSettings":aUserApiSetting,
            "IsRecognitionAdmin":aEnableRecognitionConfiguration,
            "IsTenantAdmin":aIsTenantAdministrator,
            "TenantGuid":aTenantGuid,
            "LicenceGuid": aLicenceGuid,

            "EmailUpdate":true,
            "PasswordUpdate":false,
            "NameUpdate":true,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":true,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":true,
            "IsTenantAdminUpdate":true,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":true,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },

        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}

export async function TenantFrontendApi_DeleteUser(aSecurityToken,aUserGuid)
{
    return await axios.delete( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/User?userguid=" + aUserGuid,                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }   
    )
}

export async function TenantFrontendApi_GetResellerUsers(aSecurityToken){
    return await axios.get(
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/userlist/byreseller",                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }
    )
}

export async function TenantFrontendApi_UpdateTenant(aSecurityToken,aTenantGuid,aTenantCompany,aTenantAddress1,aTenantAddress2,aTenantPostalCode,aTenantCity,aTenantVATNumber,aTenantApiSettings,aTenantCustomId)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenant",
        {
            "TenantGuid":aTenantGuid,
            "Company":aTenantCompany,
            "CompanyAddressLine1":aTenantAddress1,
            "CompanyAddressLine2":aTenantAddress2,
            "CompanyPostalCode":aTenantPostalCode,
            "CompanyCity":aTenantCity,
            "CompanyPhone":aTenantCity,
            "VATNumber":aTenantVATNumber,
            "ApiSettings":aTenantApiSettings,
            "CustomId":aTenantCustomId,
            
            "ResellerGuidUpdate":false,
            "IsResellerGuidUpdate":false,
            "CompanyUpdate":true,
            "CompanyAddressLine1Update":true,
            "CompanyAddressLine2Update":true,
            "CompanyPostalCodeUpdate":true,
            "CompanyCityUpdate":true,
            "CompanyCountryUpdate":false,
            "CompanyPhoneUpdate":true,
            "VATNumberUpdate":true,
            "ApiSettingsUpdate":true,
            "DefaultUserApiSettingsUpdate":false,
            "IntegrationOneUpdate":false,
            "CustomIdUpdate":true,
            "ApiKeyUpdate":false
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_GetResellerLicenceList(aSecurityToken)
{
    return await axios.get( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/licencelist",                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken
            }                
        }   
    ) 
}

export async function TenantFrontendApi_AddNewTenant(aSecurityToken,aResellerGuid,aCompany,aCompanyAddressLine1,aCompanyAddressLine2,aCompanyPostalCode,aCompanyCity,aCompanyCountry,aCompanyPhone,aVATNumber,aApiSettings,aCustomId)
{
    return await axios.post( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenant?createdby=TenantFrontEnd",
        {
            "TenantGuid":"",
            "ResellerGuid":aResellerGuid,
            "IsResellerGuid":"",
            "Company":aCompany,
            "CompanyAddressLine1":aCompanyAddressLine1,
            "CompanyAddressLine2":aCompanyAddressLine2,
            "CompanyPostalCode":aCompanyPostalCode,
            "CompanyCity":aCompanyCity,
            "CompanyCountry":aCompanyCountry,
            "CompanyPhone":aCompanyPhone,
            "VATNumber":aVATNumber,
            "ApiSettings":aApiSettings,
            "IntegrationOne":false,
            "CustomId":aCustomId,
            "IsTest":false
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_AddNewUser(aSecurityToken,aUserEmail,aUserPassword,aUserName,aUserSettings,aUserApiSettings,aUserRecognisionAdmin,aTenantGuid,aLicenceGuid,aUserTenantAdmin)
{
    return await axios.post( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user?licenceautoincrease=true&createdby=TenantFrontEndApi",
        {
            "UserGuid":"",
            "Email":aUserEmail,
            "Password":aUserPassword,
            "Name":aUserName,
            "Settings":aUserSettings,
            "ApiSettings":aUserApiSettings,
            "IsActive":true,
            "IsRecognitionAdmin":aUserRecognisionAdmin,
            "IsTenantAdmin":aUserTenantAdmin,
            "IsGlobalAdmin":false,
            "TenantGuid":aTenantGuid,
            "LicenceGuid":aLicenceGuid,
            "ServerScripts":true
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_DeleteTenantLicence(aSecurityToken,aTenantGuid,aLicenceGuid,aDeletedLicenceCount)
{
    return await axios.delete( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenantlicence?tenantguid=" + aTenantGuid + "&licenceguid=" + aLicenceGuid + "&count=" + aDeletedLicenceCount,                       
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}

export async function TenantFrontendApi_DeleteTenant(aSecurityToken,aTenantGuid)
{
    return await axios.delete( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenant?tenantguid=" + aTenantGuid,                       
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )   
}

export async function TenantFrontendApi_GetReseller(aSecurityToken,aResellerGuid)
{
    return await axios.get(
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/reseller?resellerguid=" + aResellerGuid,
        {
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }      

        }
    )
}

export async function TenantFrontendApi_UpdateReseller(aSecurityToken,aResellerGuid,aDefaultTenantApiSettings,aDefaultTenantUserApiSettings)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/reseller",
        {
            "ResellerGuid":aResellerGuid,
            "DefaultTenantApiSettings":aDefaultTenantApiSettings,
            "DefaultTenantUserApiSettings":aDefaultTenantUserApiSettings,
            
            "DefaultTenantUserApiSettingsUpdate":true,
            "DefaultTenantApiSettingsUpdate":true
            
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}

export async function TenantFrontendApi_PostTenantLicence(aSecurityToken,aTenantGuid,aLicenceGuid,aLicenceCount)
{
    return await axios.post( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/tenantlicence",
        {
            
            "TenantGuid":aTenantGuid,
            "LicenceGuid":aLicenceGuid,
            "Count": aLicenceCount
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_ChangeUserPassword(aSecurityToken,aUserGuid,aUserPassword,aTenantGuid)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "TenantGuid":aTenantGuid,
            "Password" : aUserPassword,

            "EmailUpdate":false,
            "PasswordUpdate":true,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":false,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_DeleteUserLicence(aSecurityToken,aUserGuid,aTenantGuid)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "TenantGuid":aTenantGuid,
            "LicenceGuid":"",

            "EmailUpdate":false,
            "PasswordUpdate":false,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":true,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}

export async function TenantFrontendApi_GetLicence(aSecurityToken,aLicenceGuid)
{
    return await axios.get(
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/licence?licenceguid=" + aLicenceGuid,
        {
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }      

        }
    )
}


export async function TenantFrontendApi_BlockUser(aSecurityToken,aUserGuid,aTenantGuid,aUserActiveStatus)
{
    return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "TenantGuid":aTenantGuid,
            "IsActive":!aUserActiveStatus,

            "EmailUpdate":false,
            "PasswordUpdate":false,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":true,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":false,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },

        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}

export async function TenantFrontendApi_ChangeUserLicense(aSecurityToken,aUserGuid,aTenantGuid,aLicenseGuid)
{

   return await axios.put( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/user",
        {
            "UserGuid":aUserGuid,
            "TenantGuid":aTenantGuid,
            "LicenceGuid":aLicenseGuid,
            
            "EmailUpdate":false,
            "PasswordUpdate":false,
            "NameUpdate":false,
            "SettingsUpdate":false,
            "ApiSettingsUpdate":false,
            "IsActiveUpdate":false,
            "IsRecognitionAdminUpdate":false,
            "IsTenantAdminUpdate":false,
            "IsGlobalAdminUpdate":false,
            "LicenceGuidUpdate":true,
            "LicenceGuidAutoDecreaseTenantLicence":false,
            "LicenceGuidAutoIncreaseTenantLicence":false,
            "ServerScriptsUpdate":false
        },

        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )

}

export async function TenantFrontendApi_PostSendEmail(aSecurityToken,aUserEmail,aUserName)
{
    const Domain = "https://userportal.cloudcti.nl/"
    const EmailBody = `
        <html>
        <body>
            <p>Welcome ${aUserName}! You are invited to CloudCTI!</p>
            <p>Click <a href="${Domain}">here</a> to get started.</p>
        </body>
        </html>
    `;
    
    return await axios.post( 
        "https://integrationcloudcti.azurewebsites.net/tenantfrontend/api/sendemail",
        {
            
            "Recipient":aUserEmail,
            "Subject":"CloudCTI - You are invited",
            "Body":EmailBody,
            "IsHtml": true // Specify the content type as HTML
        },                        
        {                
            headers:                
            {                
                Authorization: "Bearer " + aSecurityToken,
                ContentType: "application/json"
            }                
        }   
    )
}