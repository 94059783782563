import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_GetLicenceList, TenantFrontendApi_GetTenant} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import BlueBar from "./BlueBar"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"

import './Tenant.css';
import './EditUser.css';
import './EditTenant.css';
import './Licences.css';

function TenantLicences(){

    const [mLicenceList,SetLicenceList] = useState([])
    const [mTenant,SetTenant] = useState("")

    const [mRepaint,SetRepaint] = useState()
    const navigate = useNavigate();

    useEffect(() => {

        async function GetLicences(aTenantGuid) {
            try
            {
                console.log("Getting licences")
                const LicencesResponse = await TenantFrontendApi_GetLicenceList(sessionStorage.getItem("token"),aTenantGuid)
                SetLicenceList(LicencesResponse.data)
            }

            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetTenant(aTenantGuid) {
            try
            {   
                console.log("Getting Tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"),aTenantGuid)
                SetTenant(TenantResponse.data)
            }

            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }

        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const tenantguid = ciParams.get('tenantguid')

        GetLicences(tenantguid)
        GetTenant(tenantguid)

    },[navigate] );

    function languageChanged()
    {
        SetRepaint(!mRepaint)
    }
    
    function TenantPage()
    {
        navigate('../tenant?tenantguid=' + mTenant.TenantGuid)
    }

    function HomePage()
    {
        return(
            navigate('../')
        )
    }

    return(
        <span>

            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={1} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                
                    <tr className="pagetop3">
                        <td colSpan={6} height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                        <tr height="60">
                                            <td width="40" align="left">
                                                <img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={()=>HomePage()}/>
                                                <span width="40" align="left" className="underline pointer bold" onClick={()=>TenantPage()}>{Translate("Tenants")}</span>
                                                <span width="20" className="blue" align="center"> / </span>
                                                <span align="left" className="bold underline pointer" onClick={()=>navigate('../tenant?tenantguid=' + mTenant.TenantGuid)}>{mTenant.Company}</span>
                                                <span width="20" className="blue" align="center"> / </span>
                                                <span className="bold">Tenant licenses </span>
                                            </td>                                        
                                        </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                        
                    <tr height="75">
                        <td className="bold fontsize30 width30" colSpan={4}>{Translate("Tenant licenses")}</td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/> 

                </tbody>
            </table>
            
            <table className="width1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr>
                        
                        <td className="tenantusertable borderleft widthlicencename bordertop" >{Translate("License")}</td>
                        <td className="tenantusertable borderleft widthlicencedescription bordertop" >{Translate("Description")}</td>
                        <td className="tenantusertable borderleft borderright bordertop">{Translate("Count")}</td>
                    </tr>
                    
                {mLicenceList && mLicenceList.length > 0 ? (
                    mLicenceList.map((licence, index) => (
                        <tr height="60" key={index}>
                            
                            <td className="tenantusertablecontent borderleft borderbottom bold">{licence.Name}</td>
                            <td className="tenantusertablecontent borderleft borderbottom bold">{licence.Description}</td>
                            <td className="tenantusertablecontent borderleft borderbottom bold borderright">{licence.Count}</td>
                        </tr>
                        ))
                        ) : (
                        
                        <tr>
                            <td className="bold center borderleft borderbottom borderright" align="center" colSpan={4}>{Translate("No license available")}</td>
                        </tr>
                    )
                }
                
                </tbody>
            </table>

            {/*Footer Table*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>

        </span>
    )
}

export default TenantLicences