import React,{useState,useEffect} from "react"
import { useNavigate } from "react-router-dom"

import {TenantFrontendApi_GetReseller,TenantFrontendApi_UpdateReseller} from "./TenantFrontendApi"
import { Translate } from "./Translator"
import Header from "./Header"
import MainMenu from "./MainMenu"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"

import './DefaultSettings.css';
import './Tenant.css';
import './EditUser.css';
import './EditTenant.css';
import './Licences.css';
import BlueBar from "./BlueBar"

function DefaultSettings(){

    const navigate = useNavigate()
    const [mRepaint,SetRepaint]= useState(false)

    const [mReseller,SetReseller] = useState("")

    const [mUpdatedSuccessfully,SetUpdateSuccessfully] = useState(false)
    const [mUpdateBegin,SetUpdateBegin] = useState(false)

    useEffect(() => {

        async function GetReseller() {
            try
            { 
                console.log("Getting reseller")
                console.log(sessionStorage.getItem("resellerguid"))
                
                if(sessionStorage.getItem("resellerguid"))
                {
                     const Response = await TenantFrontendApi_GetReseller(sessionStorage.getItem("token"),sessionStorage.getItem("resellerguid"))
                     SetReseller(Response.data)
                }
                
                else
                {
                    SetReseller("")
                }
            }

            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetReseller()
        
    }, []);

    const SetDefaultTenantApiSettings = (aDefaultTenantApiSettings) =>
    {
        SetReseller(prevReseller => ({
            ...prevReseller,
            DefaultTenantApiSettings: aDefaultTenantApiSettings
        }));
        SetRepaint(!mRepaint);
    }
    
    const SetDefaultTenantUserApiSettings = (aDefaultTenantUserApiSettings) =>
    {
        SetReseller(prevReseller => ({
            ...prevReseller,
            DefaultTenantUserApiSettings: aDefaultTenantUserApiSettings
        }));
        SetRepaint(!mRepaint);
    }
    
    function UpdateInformation()
    {
        SetUpdateBegin(true)
        return(
            UpdateReseller()
        )
    }

    async function UpdateReseller() {

        try
        {
            console.log("Updating reseller")
            const UpdateResellerResponse = await TenantFrontendApi_UpdateReseller(sessionStorage.getItem("token"),sessionStorage.getItem("resellerguid"),mReseller.DefaultTenantApiSettings,mReseller.DefaultTenantUserApiSettings)
            console.log(JSON.stringify(UpdateResellerResponse))
            SetUpdateSuccessfully(true)
            navigate('../reseller')
        }

        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    let PageLocation = <span/>
    PageLocation = <tr height="60">
                        <td width="40" align="left">
                            <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>              
                            <span className="bold" width="40" align="left" text-align="button" >{Translate("Default settings")}</span> 
                        </td>
                    </tr>


    return(
        <span>

            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={4} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    {PageLocation}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                

                    <tr className="pagetop4">
                        <td colSpan={4} height="75" valign="left" className="activetenants width30">
                            {Translate("Default settings")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>                
                </tbody>
            </table>
            
            <table className="width1200 cloudctibackgroundtable" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr height="40" ></tr>
                    
                    <tr>
                        <td className=""></td>
                        <td className="bold fontsizesmall">{Translate("Default tenant API settings")}
                        </td>
                    </tr>

                    <tr height="10"/>
                    
                    <tr  height="110">
                        <td width="40"></td>
                        <td className="">
                                <textarea value={mReseller.DefaultTenantApiSettings} onChange={e=>SetDefaultTenantApiSettings(e.target.value)} className="textareadefaultsettings" ></textarea></td>
                        <td></td>
                    </tr>

                    <tr height="20"/>
                    
                    <tr height="30" ></tr>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("Default user API settings")}
                        </td>
                    </tr>
                    
                    <tr height="10"/>
                    
                    <tr  height="40">
                        <td width="40"></td>
                        <td width="1000" className="">
                                <textarea value={mReseller.DefaultTenantUserApiSettings} onChange={e=>SetDefaultTenantUserApiSettings(e.target.value)} className="textareadefaultsettings" ></textarea>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="10"/>

                    <tr>
                        <td width="40"/>
                            { mUpdateBegin ? 
                                <td>{mUpdatedSuccessfully ? <td className="green bold">{Translate("Updated it Successfully")}</td> : <td className="red bold">{Translate("Default settings are not updated")}</td>}</td>
                                :
                                <td></td>
                            }
                    </tr>
                    
                    <tr height="30"/>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">
                            <button className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=> UpdateInformation()}>{Translate("Save settings")}</button> 
                        </td>
                    </tr>
                
                    <tr height="30"/>
                    
                </tbody>
            </table>

            {/*Footer Table*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>
            
            <Footer/>
        
        </span>
    )
}

export default DefaultSettings