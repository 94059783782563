import React from "react";

import { Translate } from "./Translator"

function Footer(){
    return(
        <span>
         <table width="100%" className="footer" border="1">
                <tbody>
                    <tr>
                        <td colSpan={6} className="bluebelowline"></td>
                    </tr>

                    <tr height="20"></tr>

                    <tr>
                        <td className="freefooter" width="320"></td>
                        <td className="bold darkgrey footerresizeelement">© 2024 CloudCTI</td>
                        <td className="freefooter"></td>
                        <td className="footerresizeelement" width="100"><a className="bold darkgrey " href="https://cloudcti.nl/">{Translate("Home")}</a></td>
                        <td className="footerresizeelement" width="200"><a className="bold darkgrey" href="https://downloadcloudcti.blob.core.windows.net/files/Website/Terms%20of%20Delivery%20CloudCTI-2015-1%20EN.pdf">{Translate("Terms & Conditions")}</a></td>
                        <td className="footerresizeelement marginrightresize"><a className="bold darkgrey" href="https://cloudcti.nl/privacy-policy">{Translate("Privacy Policy")}</a></td>
                    </tr>

                    <tr height="60"/>

                </tbody>
            </table>       
        </span>
    )
}

export default Footer