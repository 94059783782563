import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { TenantFrontendApi_GetTenant,TenantFrontendApi_GetUserList,TenantFrontendApi_BlockUser, TenantFrontendApi_DeleteUser } from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import BlueBar from "./BlueBar"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"
import EditLogo from "./Assets/EditLogo.png";
import Trash from "./Assets/Trash.png";
import Block from './Assets/Block.png'

import './Tenant.css';
import './EditUser.css';
import './EditTenant.css';
import './Licences.css';

function TenantUsers(){
    
    const [mUserList,SetUserList] = useState([])
    const [mTenant, SetTenant] = useState(null)

    const navigate = useNavigate()
    const [mRepaint,SetRepaint] = useState()
    const mSearchValue = ""
    const [mDeleteUser,SetDeleteUser] = useState("")
    

    useEffect(() => {

        async function GetTenant(aTenantGuid) {
            try
            {   
                console.log("Getting tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"),aTenantGuid)
                SetTenant(TenantResponse.data)  
            }
            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetUsers(aTenantGuid) {
            try
            {
                console.log("Getting users")
                console.log(aTenantGuid)
                const UsersResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"),aTenantGuid)
                SetUserList(UsersResponse.data)
            }

            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=user')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }

        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const tenantguid = ciParams.get('tenantguid')

        GetUsers(tenantguid)
        GetTenant(tenantguid)

    }, [navigate]);
    

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    async function BlockUser(aUser)
    {
        try
        {
            console.log("Blocking User")
            const BlockUserResponse = await TenantFrontendApi_BlockUser(sessionStorage.getItem("token"),aUser.UserGuid,mTenant.TenantGuid,aUser.IsActive)
            console.log(BlockUserResponse.data)

            const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"),mTenant.TenantGuid)
            SetUserList(UserListResponse.data) 
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    async function DeleteUser()
    {
        try
        {
            console.log("Deleting User")
            const DeleteUserResponse = await TenantFrontendApi_DeleteUser(sessionStorage.getItem("token"),mDeleteUser.UserGuid)
            console.log(DeleteUserResponse)
            SetDeleteUser("")

            const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"),mTenant.TenantGuid)
            SetUserList(UserListResponse.data)   
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetDeleteUser("")
        }
    }

    let filteredUserList = []
    const lowerSearchValue = mSearchValue.toLowerCase()

    if (lowerSearchValue === "") 
    {
        filteredUserList = mUserList
    } 
    else 
    {
        filteredUserList = mUserList.filter(
            user => user.Name.toLowerCase().includes(lowerSearchValue)
        )
    }   

    return(
        <span>
            
            <Header onLanguageChanged={() => languageChanged()}/>
            <MainMenu MainMenuNumber={1} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                
                    <tr className="pagetop3">
                        <td colSpan={6} height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0}>
                                <tbody>
                                    <tr height="60">
                                        <td align="left">
                                            <img alt="Home logo" className="HomeLogo up6px " src={HomeLogo}/>
                                            <span className="underline pointer bold" onClick={() => navigate("../reseller")}>{Translate("Tenants")}</span>
                                            <span width="20" className="blue" align="center"> / </span>
                                            {mTenant !== null ? <span className="underline pointer bold" onClick={() => navigate("../tenant?tenantguid=" + mTenant.TenantGuid)}>{mTenant.Company ?? ""}</span> : <span/>}
                                            <span width="20" className="blue" align="center"> / </span>
                                            <span className="bold">{Translate("Users")}</span>
                                        </td>    
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    
                    <tr height="75">
                        <td className="bold fontsize30 width30" colSpan={4}>{Translate("Tenant users")}</td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" ><BlueBar/></td>
                    </tr>

                    <tr height="50"/>

                    

                </tbody>
            </table>

            <table className="width1200" cellPadding={0} cellSpacing={0} align="center" border="1">
                    <tbody>
                        <tr>
                            <td className="tenantusertable tenantusertableheadername borderleft" >{Translate("Name")}</td>
                            <td className="tenantusertable tenantusertableheadername" >{Translate("E-mail / Sign-in")}</td>
                            <td className="tenantusertable tenantusertableheadername " >{Translate("Assigned license")}</td>
                            <td colSpan={3} className="tenantusertable ">{Translate("Change")}</td>
                        </tr>

                        {filteredUserList.length > 0 ? (
                        filteredUserList.sort((a,b) => a.Name.localeCompare(b.Name)).map((user, index) => (
                        <tr height="60" key={index} className={user.IsActive ? "" : "lightfontcolor"}>
                            <td className="tenantusertablecontent borderbottom bold underline pointer" onClick={()=>navigate('../user?userguid=' + user.UserGuid + '&tenantguid=' + mTenant.TenantGuid)}>{user.Name}</td>
                            <td className="tenantusertablecontent borderbottom">{user.Email}</td>
                            <td className="tenantusertablecontent underlinetext borderbottom">{user.LicenceName}</td>
                            <td className=" borderbottom">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <img align="right" alt="edit" src={EditLogo}></img>
                                                            </td>
                                                            <td>
                                                                <td className=" underlinetext tenantusertableedit pointer" onClick={() => navigate('../edituser?userguid=' + user.UserGuid + '&tenantguid=' + mTenant.TenantGuid)}>{Translate("Edit")}</td>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                
                                            </td>
                                           
                                            <td><img className="trashwidth" align="right" alt="DeleteLogo" src={Trash}></img></td>
                                            <td align="left" valign="center" className=" underlinetext tenantusertableedit pointer" onClick={() => SetDeleteUser(user) }>{Translate("Delete")}</td>
                                            <td><img className="trashwidth" align="right" alt="BlockLogo" src={Block}></img></td>
                                            <td width="*" align="left" valign="center" className = {user.IsActive ? "underlinetext tenantusertableedit pointer" : "underlinetext tenantusertableedit pointer red" } 
                                                onClick={() => BlockUser(user)}>
                                                {user.IsActive ? Translate("Block sign-in") : Translate("Unblock sign-in") }   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        ))
                        ) : (
                        <tr>
                            <td className="borderleft borderbottom  bold" align="center" colSpan={7}>{Translate("No user found")}</td>
                        </tr>
                        )}            
                </tbody>
            </table>
              
            {/*Footer Table*/}
            <table>
                <tbody>
                    <tr height="180"></tr>
                </tbody>
            </table>

            <Footer/>

            <Modal animation={false} style={{opacity:1}} show={mDeleteUser !== ""} onHide={() => {SetDeleteUser("")}} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("Are you sure you want to delete this user")}</span></Modal.Title>
                </Modal.Header>

                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {DeleteUser()}}>{Translate("Delete")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() => {SetDeleteUser("")}}>Cancel</Button>
                </Modal.Footer>
            </Modal>
                        
        </span>
    )
}

export default TenantUsers