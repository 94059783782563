import React from "react";

import "./HomePage.css";
import Reseller from "./Reseller"

function HomePage(){

    return(
        <span>
            <Reseller/>
        </span>
    )
}

export default HomePage;