import React from "react";

function BlueBar() {

    return <table cellPadding="0" cellSpacing="0" width="50" height="10">
    <tbody>
        <tr>
            <td className="bluebar"></td>
        </tr>
    </tbody>     
    </table>
}

export default BlueBar