import React from "react";
import Cookies from 'js-cookie'

import LanguageSelect from "./LanguageSelect"

import './Header.css'

import AccountLogo from "./Assets/AccountLogo.svg"
import LanguageIcon from "./Assets/Language.png"
import SignOut from "./Assets/SignOut.png"

function Header(props){
    
    function handleSignout(){

        sessionStorage.clear()
        localStorage.clear()

        Cookies.remove('resellerportal', { domain: '.cloudcti.nl', path: '/' })

        window.location.reload()
    }

    return(
        <span>
            <table width="100%" className="main" cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className="pagetop1" height="60">   
                        <td width="*"/>
                        <td width="30" valign="center"><img alt="" src={LanguageIcon} className="languageicon" /></td>
                        <td width="100" valign="center"><LanguageSelect OnLanguageChanged={() => props.onLanguageChanged()}/></td>
                        <td width="1" valign="top">
                            <table width="30">  
                                <tbody> 
                                    <tr height="50">
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width="30" valign="left"><img alt="Account" src={AccountLogo} className="accountlogo" /></td>
                        <td width="100" valign="left">{sessionStorage.getItem("username")}</td>
                        <td width="30" valign="center"></td>
                        <td width="30" valign="center"><img alt="Sign" src={SignOut} className="signoutbutton" onClick={() => handleSignout()}/></td>
                    </tr>
                </tbody>
            </table>
        </span>
    )
}

export default Header