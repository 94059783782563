import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_GetTenantList } from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import BlueBar from "./BlueBar"
import Footer from "./Footer"
import MainMenu from "./MainMenu";

import AccountLogo from "./Assets/AccountLogo.svg"
import HomeLogo from "./Assets/HomeLogo.png"
import SearchButton from "./Assets/SearchButton.png"
import DocumentLogo from "./Assets/DocumentLogo.jpg"
import PlusIcon from "./Assets/PlusIcon.jpg"

import './Reseller.css'

function Reseller(){

    const [mRepaint, SetRepaint] = useState()
    const navigate = useNavigate();
    const [mSearchValue,SetSearchValue]=useState("")
    const [mAllTenantList,SetAllTenantList] = useState([])
    
    useEffect(() => {

        async function GetTenantList() {

            try
            {
                console.log("Getting tenants")
                const TenantListResponse = await TenantFrontendApi_GetTenantList(sessionStorage.getItem("token"))
                SetAllTenantList(TenantListResponse.data)
                sessionStorage.setItem("resellerguid",TenantListResponse.data[0].ResellerGuid)
            }
            
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetTenantList()
        

    }, []);

    function CreateNewTenantButton(){
        return(
            navigate('../addnewtenant')
        )
    }

    function TenantUsers(aTenantGuid){
        navigate('../tenantusers?tenantguid=' + aTenantGuid)
    }

    function TenantLicences(aTenantGuid){
        navigate('../tenantlicences?tenantguid=' + aTenantGuid)
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    let filteredTenantList = []
    const lowerSearchValue = mSearchValue.toLowerCase()

    if (lowerSearchValue === "") 
    {
        filteredTenantList = mAllTenantList
    } 
    else 
    {
        filteredTenantList = mAllTenantList.filter(
            tenant => ( tenant.Company.toLowerCase().includes(lowerSearchValue) ||
                        tenant.ApiSettings.toLowerCase().includes(lowerSearchValue)
                    )
        )
    }   
    
    return(
    
        <span>
            
            <Header onLanguageChanged={() => languageChanged()}/>
            
            <MainMenu MainMenuNumber={1} />
            
            <table className="tenanttable" width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                                    
                    <tr className="pagetop3">
                        <td colSpan={4} height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px" >
                                <tbody>
                                    <tr height="60">
                                        <td width="80" align="left">
                                            <img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/>
                                            <span width="40" align="left" className="bold">{Translate("Tenants")}</span>
                                        </td>                                            
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td className="freespace"/>
                        <td colSpan={4} height="75" valign="left" className="activetenants">
                            {Translate("Active tenants")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td className="freespace"/>
                        <td colSpan={4} className="horizontalLine" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>
                </tbody>
            </table>

            <table className="width1200" align="center" >
                <tbody>

                    <tr>

                        <td></td>
                        <td colSpan={2} height="50" valign="left">
                            <input type="text" placeholder={Translate("Search")} className="searchtenants" onChange={e=>SetSearchValue(e.target.value)}/>
                            <img alt="Search" className="searchimage pointer" src={SearchButton} onClick={()=>SetRepaint(!mRepaint)} />    
                        </td>

                        <td className="distanceresize" align="center"></td>
                        
                        <td colSpan={2} align="right">
                            <table className="blackbackground borderradius">
                                <tbody>
                                    <tr className="pointer" onClick={() => CreateNewTenantButton()}>
                                        <td width="8"/>
                                        <td><img className="width50" src={PlusIcon} alt="plus icon"/></td>
                                        <td className="bold white">{Translate("Add tenant")}</td>
                                        <td width="20"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>

                    </tr>

                    <tr height="10"/>
                </tbody>
            </table>
            
            {/*Tenants table*/}
            <table className="width1200" align="center">
                <tbody>         
                    <tr className="tanenttableheader ">
                        <td colSpan={2} className="bold bordertop borderleft bluebackground white ">
                            {Translate("Name")}
                        </td>
                        <td className="bold white bordertop borderleft bluebackground widthusertenantstable white"># {Translate("Users")}</td>
                        <td colSpan={2} width="550" className="bold white bordertop borderleft bluebackground white">{Translate("# Bought licenses")}</td>
                    </tr>

                    {filteredTenantList && filteredTenantList.length > 0 ? (
                            filteredTenantList.sort((a, b) => a.Company.localeCompare(b.Company)).map((tenant,index) => (
                                
                                <tr className="contentenatable" key={index}>
                                    <td colSpan={2} className="tenatsname borderbottomright underline pointer bold" onClick={()=>navigate('../tenant?tenantguid=' + tenant.TenantGuid)} >{tenant.Company}</td>
                                    <td className="borderbottomright">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img alt="Account" width="40" src={AccountLogo}/>
                                                    </td>
                                                    <td className="viewinfo pointer bold" onClick={()=>TenantUsers(tenant.TenantGuid)}>
                                                        {tenant.UserCount}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td className="borderbottomright" colSpan={2}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <img alt="Document" width="40" src={DocumentLogo}/>
                                                    </td>
                                                    <td className="viewinfo pointer" onClick={()=>TenantLicences(tenant.TenantGuid)}>
                                                        {tenant.LicenceSummary ? tenant.LicenceSummary : "No license available"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                        ))) : 
                        (
                            <tr>
                            <td className="borderleft borderbottom borderright bold" align="center" colSpan={5}>{Translate("No tenant found")}</td>
                        </tr>)
                    }
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr height="20"/>
                </tbody>
            </table>

            <Footer/>
            
        </span>    
    )
}

export default Reseller