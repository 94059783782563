import React,{useState,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_GetTenant,TenantFrontendApi_GetUserList,TenantFrontendApi_GetLicenceList,TenantFrontendApi_DeleteTenantLicence,TenantFrontendApi_BlockUser, TenantFrontendApi_DeleteTenant, TenantFrontendApi_DeleteUser, TenantFrontendApi_PostSendEmail} from "./TenantFrontendApi";
import { Translate } from "./Translator"

import Header from "./Header"
import MainMenu from "./MainMenu"
import BlueBar from "./BlueBar"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"
import SearchButton from "./Assets/SearchButton.png"
import EditLogo from "./Assets/EditLogo.png";
import Trash from './Assets/Trash.png'
import CloseLogo from "./Assets/CloseLogo.png"
import CloseLogoDelete from "./Assets/CloseLogo.jpg"
import EditInformationLogo from "./Assets/EditInformationlogo.jpg"
import PlusIcon from "./Assets/PlusIcon.jpg"
import Block from './Assets/Block.png'
import Envelop from './Assets/EnvelopLogo.png'

import './Tenant.css';

function Tenant(){

    const [mRepaint,SetRepaint] = useState()
    const navigate = useNavigate();

    const [mTenantGuid, SetTenantGuid] = useState()
    const [mTenant, SetTenant] = useState(null)
    const [mDeletedTenantGuid,SetDeletedTenantGuid] = useState("")

    const [mLicenceList,SetLicenceList]=useState([])
    const [mExpireLicence,SetExpireLicence] = useState("")
    const [mExpireLicenceCount,SetExpireLicenceCount] = useState(1)
    const [mDeletingLicenseComment,SetDeletingLicenseComment] = useState("")

    const [mUserSearchValue,SetUserSearchValue] = useState("")
    const [mDeleteUser,SetDeleteUser] = useState("")
    const [mInvitedUser,SetInvitedUser] = useState("")
    const [mAllUserList,SetAllUserList] = useState([])

    useEffect(() => {

        async function GetTenant(aTenantGuid) {
            try
            {
                console.log("Getting tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"),aTenantGuid)
                SetTenant(TenantResponse.data)
            }

            catch (ex)
            {
                var error = ex;
                var errorCode = null;
                try 
                {
                    if (ex.response && ex.response.status) {
                        errorCode = ex.response.status;
                        console.log(errorCode)
                    }
                    navigate('../error?errorcode=' + errorCode + '&error=tenant')
                    error = ex.response.data.Exception;
                } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetUsers(aTenantGuid)
        {
            try{
                console.log("Getting Users")
                const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"),aTenantGuid)
                SetAllUserList(UserListResponse.data)
            }
            catch(ex){
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetLicences(aTenantGuid)
        {
            try{
                console.log("Getting Licenses")
                const LicenceListResponse = await TenantFrontendApi_GetLicenceList(sessionStorage.getItem("token"),aTenantGuid)
                SetLicenceList(LicenceListResponse.data)
            }
            catch(ex){
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }
       
        //Get the parameters from the URL                            
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const tenantguid = ciParams.get('tenantguid')
        SetTenantGuid(tenantguid)

        GetTenant(tenantguid)
        GetUsers(tenantguid)
        GetLicences(tenantguid)
        
    }, [navigate]);

    function EditInformationButton(aTenantGuid){
        return(            
            navigate('../edittenant?tenantguid='+aTenantGuid)
        )
    }

    function BuyLicence(){
        navigate('../buylicence?tenantguid=' + mTenantGuid)
    }           

    function AddNewUserButton(aTenantGuid){
        navigate('../addnewuser?tenantguid=' + mTenant.TenantGuid)
    }

    function ExpireLicence()
    {
        if(mExpireLicence.Count - mExpireLicenceCount === 0)
        {
            const FilteredUserList = mAllUserList.filter(
                user => (
                    user.LicenceGuid.includes(mExpireLicence.LicenceGuid) 
                )
            )
            if(FilteredUserList.Count > 0)
            {
                return(SetDeletingLicenseComment("Users of this tenant have this license in use, you can not remove all of them !"))
            }
            else
            {
                return(DeleteTenantLicence())
            }
        }
        return(DeleteTenantLicence())
         
    }

    async function DeleteTenantLicence()
    {
        try
        {
            console.log("Deleting Licence")
            const DeleteLicecnceResponse = await TenantFrontendApi_DeleteTenantLicence(sessionStorage.getItem("token"),mTenantGuid,mExpireLicence.LicenceGuid,mExpireLicenceCount)
            console.log(DeleteLicecnceResponse)
            SetExpireLicence("")

            const LicenceListResponse = await TenantFrontendApi_GetLicenceList(sessionStorage.getItem("token"),mTenantGuid)
            SetLicenceList(LicenceListResponse.data)
        }

        catch(ex){
            console.log(ex.response.data)
            SetDeletingLicenseComment(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    function handleDeleteTenant(aTenantGuid)
    {
        SetDeletedTenantGuid(aTenantGuid)
    }

    async function DeleteTenant() 
    {
        try
        {
            console.log("Deleting tenant")
            const DeleteTenantResponse = await TenantFrontendApi_DeleteTenant(sessionStorage.getItem("token"),mDeletedTenantGuid)
            console.log(JSON.stringify(DeleteTenantResponse))
            SetDeletedTenantGuid("")
            navigate('../reseller')
        }
        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetDeletedTenantGuid("")
        }
    }

    function TenantPage()
    {
        navigate('../reseller')
    }

    function HomePage()
    {
        return(
            navigate('../')
        )
    }

    function languageChanged()
    {
        SetRepaint(!mRepaint)
    }

    async function DeleteUser()
    {
        try
        {
            console.log("Deleting User")
            const DeleteUserResponse = await TenantFrontendApi_DeleteUser(sessionStorage.getItem("token"),mDeleteUser.UserGuid)
            console.log(DeleteUserResponse)
            SetDeleteUser("")

            const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"),mTenantGuid)
            SetAllUserList(UserListResponse.data)   
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetDeleteUser("")
        }
    }

    async function BlockUser(aUser)
    {
        try
        {
            console.log("Blocking User")
            const BlockUserResponse = await TenantFrontendApi_BlockUser(sessionStorage.getItem("token"),aUser.UserGuid,mTenantGuid,aUser.IsActive)
            console.log(BlockUserResponse)
            const UserListResponse = await TenantFrontendApi_GetUserList(sessionStorage.getItem("token"),mTenantGuid)
            SetAllUserList(UserListResponse.data) 
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    function InviteUser(aUser)
    {
        SetInvitedUser(aUser)
    }

    function SendEmail(){
        return(SendInviteEmail())
    }

    async function SendInviteEmail()
    {
        try
        {
            console.log("Sending User Email")
            const SendMessageResponse = await TenantFrontendApi_PostSendEmail(sessionStorage.getItem("token"),mInvitedUser.Email,mInvitedUser.Name)
            console.log(SendMessageResponse)
            SetInvitedUser("")
        }

        catch(ex){
            console.log(ex.response.data)
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    if (mTenant == null)
    {
        return(<span>Retrieving data</span>)
    } 

    else
    {
        const UserFilterLC = mUserSearchValue.toLowerCase()
        const FilteredUserList = mAllUserList.filter(
            user => (
                user.Name.toLowerCase().includes(UserFilterLC) 
                || user.Email.toLowerCase().includes(UserFilterLC))
        )

        return(            
            <div>

                <Header onLanguageChanged={() => languageChanged()}/>
                
                <MainMenu MainMenuNumber={1} />

                <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                    <tbody>                
                        <tr className="pagetop3">
                            <td colSpan={6} height="75" valign="bottom">
                                <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                    <tbody>
                                        <tr height="60">
                                            <td width="40" align="left">
                                                <img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={()=>HomePage()}/>
                                                <span width="40" align="left" className="underline pointer bold" onClick={()=>TenantPage()}>{Translate("Tenants")}</span>
                                                <span width="20" className="blue" align="center"> / </span>
                                                <span align="left" className="bold">{mTenant.Company}</span>
                                            </td>                                        
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} height="75" valign="left" className="activetenants width30">
                                {mTenant.Company} {Translate("Information")}
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} className="horizontalLine width30" >
                                <BlueBar/>
                            </td>
                        </tr>

                        <tr height="50"/>                
                         
                    </tbody>
                </table>

                <table className="width1200" align="center">
                    <tbody>

                        <tr className="pagetop6">
                            <td className="activetenants width30" colSpan={3} height="50" valign="left">
                                {Translate("Information")}
                            </td>
                            <td colSpan={2} align="right">
                                <table  className="blackbackground borderradius">
                                    <tbody>
                                        <tr>
                                            <td width="10"></td>
                                            <td><img className="width50" alt="Edit information" src={EditInformationLogo}/></td>
                                            <td><button className="noneborder blackbackground bold white pointer" onClick={()=>EditInformationButton(mTenantGuid)}>{Translate("Edit information")}</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>  

                        <tr height="10"/> 

                        <tr className="contentenatable" >
                            <td className=" bold tenatsname borderbottomright" width="175">{Translate("Company name")}</td>
                            <td className=" companyname borderbottomright">{mTenant.Company}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderbottomright left-padding" width="200">{Translate("Email")}</td>
                            <td className="left-padding borderbottomright">{mTenant.CompanyPhone}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" bold tenatsname borderbottomright">{Translate("Address")}</td>
                            <td className=" companyname borderbottomright">{mTenant.CompanyAddressLine1}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderbottomright left-padding">{Translate("VAT number")}</td>
                            <td className=" left-padding borderbottomright">{mTenant.VATNumber}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" bold tenatsname borderbottomright">{Translate("Address")} 2</td>
                            <td className=" companyname borderbottomright">{mTenant.CompanyAddressLine2}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderleft borderbottomright">{Translate("Custom id")}</td>
                            <td className="left-padding borderbottomright">{mTenant.CustomId}</td>
                        </tr>
                    
                        <tr className="contentenatable" >
                            <td className=" bold tenatsname borderbottomright">{Translate("Postal code")}</td>
                            <td className=" companyname borderbottomright">{mTenant.CompanyPostalCode}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderleft borderbottomright">{Translate("Tenant API settings")}</td>
                            <td className="left-padding borderbottomright">{mTenant.ApiSettings}</td>
                        </tr>

                        <tr className="contentenatable" >
                            <td className=" bold tenatsname borderbottomright">{Translate("City")}</td>
                            <td className=" companyname borderbottomright">{mTenant.CompanyCity}</td>
                            <td className=" freespacetable" width="40"></td>
                            <td className=" bold borderleft borderbottomright">{Translate("Country")}</td>
                            <td className="left-padding borderbottomright">{mTenant.CompanyCountry}</td>
                        </tr>

                        <tr height="50"/>
                    
                        <tr className="pagetop6">
                            <td className="activetenants width30" colSpan={3} height="50" valign="left">{Translate("Bought licenses")}</td>
                            <td colSpan={2} align="right">
                                <table className="blackbackground borderradius">
                                    <tbody>
                                        <tr className="pointer" onClick={()=>BuyLicence()}>
                                            <td width="8"/>
                                            <td><img className="width50" src={PlusIcon} alt="plus icon"/></td>
                                            <td className="bold white">{Translate("Buy license")}</td>
                                            <td width="20"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr height="10"/>

                    </tbody>
                </table>

                <table className="width1200" cellPadding={0} cellSpacing={0} align="center">
                    <tbody>
                        <tr>
                            <td className="tenantlicencetableheader borderleft bordertop borderright">{Translate("License")}</td>
                            <td className="tenantlicencetableheader tenantlicencetableheaderdescription  bordertop borderright">{Translate("Description")}</td>
                            <td className="tenantlicencetableheader tenantlicencetableheaderQuantity bordertop borderright">{Translate("Quantity")}</td>
                            <td className="tenantlicencetableheader tenantlicencetableheaderQuantity bordertop borderright">{Translate("Expire")}</td>
                        </tr>

                        {mLicenceList && mLicenceList.length > 0 ? (
                        mLicenceList.map((licence, index) => (
                        <tr  key={index}>
                            <td className="borderleft borderbottom bold">{licence.Name}</td>
                            <td className="borderleft borderbottom">{licence.Description}</td>
                            <td className="borderleft borderbottom paddingleft">{licence.Count}</td>
                            <td className="borderleft borderbottom borderright">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><img alt="Close" width="30" src={CloseLogo}/></td>
                                            <td className="paddingleft underline pointer" onClick={()=>SetExpireLicence(licence)}>{Translate("Expire")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>))):(

                        <tr>
                            <td className="borderleft borderbottom borderright bold" align="center" colSpan={5}>{Translate("No license available")}</td>
                        </tr>)}

                        <tr>
                            <td colSpan={4} height="80"></td>
                        </tr>

                        <tr>
                            <td colSpan={4} className="activetenants width30"  height="50" valign="left">{Translate("Users")}</td>
                        </tr>

                        <tr>
                            <td className="width30" colSpan={2} height="50" valign="left">
                                <input id="searchvalue" value={mUserSearchValue} type="text" placeholder={Translate("Search") ?? ""} className="searchtenants" onChange={evt => SetUserSearchValue(evt.target.value)}/>
                                <img alt="Search" className="searchimage" src={SearchButton} onClick={() => SetRepaint(!mRepaint)} />
                            </td>
                            <td colSpan={2} align="right" width="323">
                                <table className="blackbackground borderradius">
                                    <tbody>
                                        <tr className="pointer" onClick={()=>AddNewUserButton(mTenant.TenantGuid)}>
                                            <td width="8"/>
                                            <td><img className="width50" src={PlusIcon} alt="plus icon"/></td>
                                            <td className="bold white">{Translate("Add user")}</td>
                                            <td width="20"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr height="10"/>

                    </tbody>
                </table>

                <table className="width1200" cellPadding={0} cellSpacing={0} align="center" border="1">
                    <tbody>
                        <tr>
                            <td className="tenantusertable tenantusertableheadername borderleft" >{Translate("Name")}</td>
                            <td className="tenantusertable tenantusertableheadername" >{Translate("E-mail / Sign-in")}</td>
                            <td className="tenantusertable tenantusertableheadername " >{Translate("Assigned license")}</td>
                            <td colSpan={3} className="tenantusertable ">{Translate("Change")}</td>
                        </tr>

                        {FilteredUserList.length > 0 ? (
                        FilteredUserList.sort((a,b) => a.Name.localeCompare(b.Name)).map((user, index) => (
                        <tr height="60" key={index} className={user.IsActive ? "" : "lightfontcolor"}>
                            <td className="tenantusertablecontent borderbottom bold underline pointer" onClick={()=>navigate('../user?userguid=' + user.UserGuid + '&tenantguid=' + mTenantGuid)}>{user.Name}</td>
                            <td className="tenantusertablecontent borderbottom">{user.Email}</td>
                            <td className="tenantusertablecontent underlinetext borderbottom">{user.LicenceName}</td>
                            <td className=" borderbottom">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <img align="right" alt="edit" src={EditLogo}></img>
                                                            </td>
                                                            <td>
                                                                <td className=" underlinetext tenantusertableedit pointer" onClick={() => navigate('../edituser?userguid=' + user.UserGuid + '&tenantguid=' + mTenantGuid)}>{Translate("Edit")}</td>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                
                                            </td>
                                           
                                            <td><img className="trashwidth" align="right" alt="DeleteLogo" src={Trash}></img></td>
                                            <td align="left" valign="center" className=" underlinetext tenantusertableedit pointer" onClick={() => SetDeleteUser(user) }>{Translate("Delete")}</td>
                                            <td><img className="EnvelopImage" align="right" alt="InviteLogo" src={Envelop}></img></td>
                                            <td align="left" valign="center" className=" underlinetext tenantusertableedit pointer" onClick={() => InviteUser(user) }>{Translate("Invite")}</td>
                                            <td><img className="trashwidth" align="right" alt="BlockLogo" src={Block}></img></td>
                                            <td width="*" align="left" valign="center" className = {user.IsActive ? "underlinetext tenantusertableedit pointer" : "underlinetext tenantusertableedit pointer red" } 
                                                onClick={() => BlockUser(user)}>
                                                {user.IsActive ? Translate("Block sign-in") : Translate("Unblock sign-in") }   
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        ))
                        ) : (
                        <tr>
                            <td className="borderleft borderbottom  bold" align="center" colSpan={7}>{Translate("No user found")}</td>
                        </tr>
                        )}            
                </tbody>
            </table>                    

            <table>
                <tbody>
                    <tr height="30"/>
                </tbody>
            </table>          
            
            {/*Delete Tenant Table*/}
            <table className="width1200 cloudctibackgroundtable" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr height="20" ></tr>

                    <tr>
                        <td width="40"></td>
                        <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><img className="DeleteIcon" alt="Close" width="20" src={CloseLogoDelete}/></td>
                                        <td className="bold fontsizelarge paddingleft paddingbottom">{Translate("Delete this tenant")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr height="20" />

                    <tr height="20"></tr>

                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">
                            <button className="red white pointer borderradios btn btn-dark" onClick={()=>{handleDeleteTenant(mTenantGuid)}}>{Translate("Delete this tenant")}</button> 
                        </td>
                    </tr>

                    <tr height="40"/>

                </tbody>
            </table>
                
            {/*Footer Table*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>
            
            {/*Animation part of expiring licences*/}
            <Modal animation={false} style={{opacity:1}} show={mExpireLicence !== ""} onHide={() => {SetExpireLicence("");SetDeletingLicenseComment("")}} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("How many licenses do you want to expire?")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body align="center">
                    <table className="scriptitembody">  
                        <tbody>                                         
                            <tr>
                                <td width="150">License amount</td> 
                                <td width="*"><input autoComplete="new-password" type="text" value={mExpireLicenceCount} onChange={evt => SetExpireLicenceCount(evt.target.value)}/></td>
                            </tr>
                            {mDeletingLicenseComment ? <tr height="10"/> : null }
                            {mDeletingLicenseComment ? <tr> <td className="red" colSpan={2}>{mDeletingLicenseComment}</td> </tr> : null }    
                        </tbody>       
                    </table> 
                    <br/>
                </Modal.Body>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {SetDeletingLicenseComment("");ExpireLicence()}}>OK</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() => {SetExpireLicence("");SetDeletingLicenseComment("")}}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            {/*Animation for deleting a user*/}
            <Modal animation={false} style={{opacity:1}} show={mDeleteUser !== ""} onHide={() => {SetDeleteUser("")}} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("Are you sure you want to delete this user")}</span></Modal.Title>
                </Modal.Header>

                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {DeleteUser()}}>{Translate("Delete")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() => {SetDeleteUser("")}}>Cancel</Button>
                </Modal.Footer>
            </Modal>

            {/*Animation for deleting a tenant*/}
            <Modal animation={false} style={{opacity:1}} show={mDeletedTenantGuid !== ""} onHide={() => SetDeletedTenantGuid("")} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("Are you sure you want to delete this tenant?")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {DeleteTenant()}}>{Translate("Delete")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() => {SetDeletedTenantGuid("")}}>Cancel</Button>
                </Modal.Footer>
            </Modal>

             {/*Animation for sending invitation email for a user*/}
             <Modal animation={false} style={{opacity:1}} show={mInvitedUser !== ""} onHide={() => SetInvitedUser("")} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title align="center"><span align="center">{("Are you sure you want to send invitation email for this user?")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Footer align="center">
                    <Button variant="dark" onClick={() => {SendEmail()}}>{Translate("Send")}</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="dark" onClick={() => {SetInvitedUser("")}}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            
    </div>)
    }
}

export default Tenant