import React,{useState,useEffect} from "react"
import { useNavigate } from "react-router-dom"

import { TenantFrontendApi_GetResellerLicenceList} from "./TenantFrontendApi"
import { Translate } from "./Translator"
import Header from "./Header"
import MainMenu from "./MainMenu"
import BlueBar from "./BlueBar"
import Footer from "./Footer"

import HomeLogo from "./Assets/HomeLogo.png"
import CheckMark from "./Assets/CheckMark.png"
import UncheckMark from "./Assets/UncheckMark.png"

import './Tenant.css';
import './EditUser.css';
import './EditTenant.css';
import './Licences.css';

function Licences(){

    const [mLicenceList,SetLicenceList]=useState([])
    const [mRepaint,SetRepaint] = useState()
    const navigate = useNavigate();

    useEffect(() => {

        async function GetLicences() {
            try
            {  
                console.log("Getting Licences")
                const LicenceListResponse = await TenantFrontendApi_GetResellerLicenceList(sessionStorage.getItem("token"))
                SetLicenceList(LicenceListResponse.data)
            }
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        GetLicences()
        
    }, []);

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    function countTrues(licence) {
        return ['ClickToDial', 'RecognitionAndScreenpop', 'EmbeddedIntegration']
            .reduce((acc, key) => acc + (licence[key] ? 1 : 0), 0);
    }

    const formatSubscriptionCost = (cost) => {
        let dollars = Math.floor(cost / 100);
        let cents = cost % 100;
        cents = cents.toString().padStart(2, '0');
        return `${dollars}.${cents}`;
      };

    const sortedLicenceList = [...mLicenceList].sort((a, b) => countTrues(a) - countTrues(b));
    console.log(sortedLicenceList)

    let PageLocation = <span/>
    PageLocation = <tr height="60">
                <td width="40" align="left">
                    <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>              
                    <span className="bold" width="40" align="left" text-align="button">{Translate("Licenses")}</span> 
                </td>
            </tr>
    return(

        <span>
            
            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={3} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    {PageLocation}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                    <tbody>                

                        <tr className="pagetop4">
                            <td colSpan={4} height="75" valign="left" className="activetenants width30">
                                {Translate("Licenses")}
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} className="horizontalLine width30" >
                                <BlueBar/>
                            </td>
                        </tr>

                        <tr height="50"/>                
                    </tbody>
            </table>

            <table className="width1200" align="center">
                <tbody>
                    <tr className="bluebackground">
                        <td colSpan={2} className="bold white borderleft bordertop">{Translate("License")}</td>
                        <td className="bold white borderleft bordertop borderright">{Translate("Click-To-Dial")}</td>
                        <td className="width40 bold white borderleft bordertop borderright">{Translate("Recognition and screenpop")}</td>
                        <td align="center" className="width40 bold white borderleft bordertop borderright">{Translate("Embedded integration")}</td>
                        <td align="center" className="bold white borderleft bordertop borderright">{Translate("Subscription")}</td>
                        <td align="center" className="leftpadding bold white borderleft bordertop borderright">{Translate("Setup")}</td>
                    </tr>

                    {sortedLicenceList && sortedLicenceList.length > 0 ? (
                    sortedLicenceList.map((licence, index) => (
                    <tr height="60" key={index}>
                        <td colSpan={2} className="borderleft borderbottom" >
                            <table>
                                <tbody width="100%">
                                    <tr>
                                        <td className="bold">{licence.Name}</td>
                                    </tr>
                                    <tr>
                                        <td>{licence.Description} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td align="center" className="borderleft borderbottom borderright">{licence.ClickToDial ? <img className="width30" alt="CheckMark" src={CheckMark} /> : <img className="width30" alt="UncheckMark" src={UncheckMark}/> }</td>
                        <td align="center" className="borderleft borderbottom borderright">{licence.RecognitionAndScreenpop ? <img className="width30" alt="CheckMark" src={CheckMark} /> : <img className="width30"  alt="UncheckMark" src={UncheckMark}/> }</td>
                        <td align="center" className="borderleft borderbottom borderright">{licence.EmbeddedIntegration ? <img className="width30" alt="CheckMark" src={CheckMark} /> : <img className="width30" alt="UncheckMark" src={UncheckMark}/> }</td>
                        <td align="center" className="borderleft borderbottom borderright">{licence.CurrencyCode === 'EUR' ? <span>&#8364;</span> : <span>$</span>} <span> </span>
                            { formatSubscriptionCost(licence.SubscriptionCost) }<span> </span> {licence.CurrencyCode}
                        </td>
                        <td align="center" className="borderleft borderbottom borderright">{licence.CurrencyCode === 'EUR' ? <span>&#8364;</span> : <span>$</span>} { formatSubscriptionCost(licence.SetupCost)}<span> </span> {licence.CurrencyCode}</td>

                    </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan={5}>{Translate("Retrieving available licenses")}</td>
                    </tr>)}
                    
                    <tr height="40"/>
                </tbody>
            </table>

            {/*footerTable*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>
            
        </span>
    )
}

export default Licences