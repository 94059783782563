import React from "react"
import PropTypes from 'prop-types'

import { GetAvailableLanguages,GetLanguageId,SetLanguageId } from "./Translator"

const LanguageSelect = (props) => {

    const UpdateLanguage = (aNewLanguageId) => {
        SetLanguageId(aNewLanguageId)
        props.OnLanguageChanged()
      }

    return <select name="Language" onChange={evt => UpdateLanguage(evt.target.value)} value={GetLanguageId() === "nederlands" ? "nederlands" : ""}>
        {GetAvailableLanguages().map((item,index) => <option key={index} value={item.Id}>{item.Caption}</option>)}
           </select>

}

LanguageSelect.propTypes = {
    OnLanguageChanged: PropTypes.func
  }

  export default LanguageSelect

