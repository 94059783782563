import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";

import { TenantFrontendApi_GetResellerLicenceList, TenantFrontendApi_GetTenant, TenantFrontendApi_PostTenantLicence} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header";
import MainMenu from "./MainMenu";
import Footer from "./Footer"
import BlueBar from "./BlueBar";

import HomeLogo from "./Assets/HomeLogo.png"

import './EditUser.css';
import './EditTenant.css';
import './Licences.css';
import './Tenant.css';
import './BuyLicence.css';

function BuyLicence(){

    const navigate = useNavigate()
    const [mRepaint,SetRepaint] = useState()

    const [mTenantGuid,SetTenantGuid] = useState("")
    const [mTenant,SetTenant] = useState("")

    const [mLicenceList,SetLicenceList] = useState([])
    const [mLicenceCount,SetLicenceCount] = useState(1)
    const [mLicenceGuid,SetLicenceGuid] = useState()

    const [mAgreePolicy,SetAgreePolicy] = useState(false)
    const [mPolicyCheckbox,SetPolicyCheckbox] = useState("")

    useEffect(() => {

        async function GetLicenceList() {
            try
            {
                console.log("Getting licences")
                const LicenceListResponse = await TenantFrontendApi_GetResellerLicenceList(sessionStorage.getItem("token"))
                SetLicenceList(LicenceListResponse.data)
                SetLicenceGuid(LicenceListResponse.data[0].LicenceGuid)
            }
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        async function GetTenant(aTenantGuid) {
            try
            {   
                console.log("Getting Tenant")
                const TenantResponse = await TenantFrontendApi_GetTenant(sessionStorage.getItem("token"),aTenantGuid)
                SetTenant(TenantResponse.data)
    
            }
            catch (ex)
            {
                var error = ex;
                try { error = ex.response.data.Exception; } catch {}  
                console.log("Error: " + error);
            }
        }

        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const tenantguid = ciParams.get('tenantguid')
        SetTenantGuid(tenantguid)
        console.log(tenantguid)
            
        GetLicenceList()
        GetTenant(tenantguid)
        
    }, []);

    function BuyLicenceButton(){
        if(mAgreePolicy){
            BuyLicenceApi()
        }
        else{
            SetPolicyCheckbox("Please agree with the policy")
        }
    }

    async function BuyLicenceApi() {
        try
        {   
            console.log("Posting Tenant licence")
            console.log(mTenantGuid + "-" + mLicenceGuid + "-" + mLicenceCount )
            const TenantLicenceResponse = await TenantFrontendApi_PostTenantLicence(sessionStorage.getItem("token"),mTenantGuid,mLicenceGuid,mLicenceCount)
            console.log(JSON.stringify(TenantLicenceResponse))
            navigate('../tenant?tenantguid=' + mTenantGuid)
        }

        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
        }
    }

    function languageChanged(){
        SetRepaint(!mRepaint)
    }
    
    let PageLocation = <span/>
    PageLocation = <tr height="60">
                        <td width="40" align="left">
                            <span width="20" align="left"><img alt="Home logo" className="HomeLogo pointer" src={HomeLogo} onClick={() => navigate('/')}/></span>              
                            <span className="underline pointer bold" width="40" align="left" text-align="button" onClick={() => navigate('../reseller')}>{Translate("Tenants")}</span>
                            <span width="20" className="blue" align="center"> / </span>
                            <span className="underline pointer bold" width="20" align="left" onClick={() => navigate('../tenant?tenantguid=' + mTenantGuid)}>{mTenant.Company}</span>
                            <span width="20" className="blue" align="center"> / </span>
                            <span className="bold" width="40" align="left" text-align="button" >{Translate("Buy license")}</span> 
                        </td>
                    </tr>

    return(
        <span>
            
            <Header onLanguageChanged={() => languageChanged()}/>

            <MainMenu MainMenuNumber={1} />

            <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>
                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    {PageLocation}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                <tbody>                

                    <tr className="pagetop4">
                        <td colSpan={4} height="75" valign="left" className="activetenants width30">
                            {Translate("Buy license")}
                        </td>
                    </tr>

                    <tr className="pagetop4">
                        <td colSpan={4} className="horizontalLine width30" >
                            <BlueBar/>
                        </td>
                    </tr>

                    <tr height="50"/>                
                </tbody>
            </table>

            <table className="cloudctibackgroundtable buylicencewidth" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr height="40" ></tr>
    
                    <tr>
                        <td className="buylicencetableledtspace width30"></td>
                        <td className="bold fontsizesmall">{Translate("SELECT LICENSE")}<span className="red">*</span></td>
                        <td width="40"></td>
                    </tr>
    
                    <tr height="10"/>

                    <tr  height="40">
                        <td width="40"></td>
                        <td>
                            <select className="selectlicencewidth" onChange={e=>SetLicenceGuid(e.target.value)}>
                                {mLicenceList.map((licence) => (
                                    <option key={licence.id} value={licence.LicenceGuid}>
                                        {licence.Name}
                                    </option>
                                    ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>

                    <tr height="20" ></tr>
    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall">{Translate("QUANTITY")}<span className="red">*</span></td>
                        <td></td>
                    </tr>
                    
                    <tr height="10"/>
                    
                    <tr  height="40">
                        <td width="40"></td>
                        <td width="11000">
                            <input defaultValue={mLicenceCount} className="selectlicencewidth" onChange={e=>SetLicenceCount(e.target.value)}></input>
                        </td>
                        <td></td>
                    </tr>
                    
                    <tr height="20"/>
                    
                    <tr>
                        <td width="40"></td>
                        <td className="bold fontsizesmall"><input onChange={e=>SetAgreePolicy(e.target.value)} type="checkbox"/>
                        <span> </span>  
                            {Translate("By clicking buy license you agree to our")}<span> </span>
                                <span className="underline pointer"><a className="black" href="https://cloudcti.nl/privacy-policy">{Translate("Privacy Policy")}</a></span> {Translate("and")}<span> </span>
                                <span className="underline pointer"><a className="black" href="https://downloadcloudcti.blob.core.windows.net/files/Website/Terms%20of%20Delivery%20CloudCTI-2015-1%20EN.pdf">{Translate("Terms and Condition")}</a></span> 
                        </td>
                    </tr>

                    <tr height="10"/>

                    <tr>
                        <td/>
                        <td className="red bold">{mPolicyCheckbox}</td>
                    </tr>

                    <tr height="20"/>

                    <tr>
                        <td width="40"/>
                        <td><button disabled={mLicenceCount && mAgreePolicy ? false : true} className="blackbackground white pointer borderradios buylicencebutton btn btn-dark" onClick={()=>BuyLicenceButton()}>{Translate("Buy license")}</button></td>
                    </tr>

                    <tr height="20"/>
                </tbody>
            </table>
                        
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>

        </span>
    )
}

export default BuyLicence